import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import CookieMobile from "../../../../components/body/pages/mobile/ng/legal/cookie"
// import CookieMobile from "../../../../components/body/pages/mobile/ng/legal/cookie";
import SEO from "../../../../components/seo"

const CookieMobilePage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/cookie-policy"}
      title="Cookie Policy | Kuda | The Money App for Africans"
    />
    <CookieMobile />
  </NoheaderFooter>
)

export default CookieMobilePage
